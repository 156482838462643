import { render, staticRenderFns } from "./ChartWidget.vue?vue&type=template&id=19c37584&scoped=true"
import script from "./ChartWidget.vue?vue&type=script&lang=js"
export * from "./ChartWidget.vue?vue&type=script&lang=js"
import style0 from "./ChartWidget.vue?vue&type=style&index=0&id=19c37584&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19c37584",
  null
  
)

export default component.exports